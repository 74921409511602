// import React from 'react'

import { useStaticQuery, graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'

/** @jsx jsx */
import { jsx } from 'theme-ui'

const ConditionsPage = () => {
    const data = useStaticQuery(pageQuery)
    const siteName = data.site.siteMetadata.siteTitle || "notre site"
    // const siteTagline = data.site.siteMetadata.siteTagline
    // const siteDesc = data.site.siteMetadata.siteDesc

    const today = new Date()

    return (
        <Layout>
            <SEO title={`Conditions d'utilisation de ${siteName}`} />

            <h1 sx={{mt: 5, mb:3}}>Conditions d'utilisation</h1>
            <h2>1. Champs d'application</h2>
            <p>Les présentes Conditions d’utilisation de LocalGraph, par Lyketil Sàrl (ci-après "{siteName}") s’appliquent à l’utilisation des applications, services et contenus de {siteName} (ci-après "Services") dont fait usage un client, considéré comme un utilisateur (ci-après «Utilisateur»), comme par exemple:</p>
            <ul>
              <li>l’utilisation des différents sites internet et applications mobiles de {siteName}</li>
              <li>l’utilisation de l'espace client de {siteName}</li>
            </ul>
            <p>En utilisant ces Services et/ou leurs fonctionnalités, l’Utilisateur accepte les présentes Conditions d’utilisation dans leur version actuelle respective.</p>
            <h2>2. Services de {siteName}</h2>
            <h3>2.1 Généralités</h3>
            <p>{siteName} peut modifier, étendre ou suspendre en tout temps ses Services, tant dans leur forme que dans leur contenu.</p>
            <p>Les descriptions de produits, les offres, les prix et autres indications similaires publiés sur les Services peuvent être modifiés en tout temps par {siteName} et, sauf convention(s) contraire(s) explicite(s) entre {siteName} et l’Utilisateur, ne lient pas ces derniers.</p>
            <p>{siteName} peut choisir de n’offrir certains Services, par exemple la publication de certains contenus, qu’à titre payant.</p>
            <p>{siteName} déploie les efforts appropriés requis afin d’utiliser constamment dans ses Services des données correctes et actualisées, étant précisé que ces dernières peuvent contenir des erreurs ou des informations incomplètes en raison de circonstances techniques ou autres. L’Utilisateur ne peut en déduire aucun droit à l’égard de {siteName}.</p>
            <p>{siteName} s’efforce de garantir un degré élevé de disponibilité des Services proposés, mais décline toute responsabilité quant au fonctionnement ininterrompu et sans défaillance de ses Services. {siteName} s’efforce de réparer, dans les délais appropriés, les pannes et défaillances techniques relevant de sa sphère d’influence.</p>
            <p>L’Utilisateur ne peut se prévaloir envers {siteName} d’aucun droit à l’utilisation des Services</p>
            <h3>2.2 Enregistrement et Compte d’Utilisateur</h3>
            <p>Pour pouvoir utiliser les Services, un enregistrement, resp. la création d’un Compte d‘Utilisateur peut être nécessaire. Seuls les Utilisateurs (qu’il s’agisse de personnes physiques ou morales) qui sont au bénéfice de l’exercice des droits civils sont autorisés à enregistrer ou à ouvrir un Compte d’Utilisateur.</p>
            <p>L’Utilisateur est tenu de fournir des indications complètes et correctes et de procéder immédiatement aux changements nécessaires si ces données ne sont plus actuelles. L’Utilisateur est seul responsable du contenu de son enregistrement et, par conséquent, seul responsable des informations qu’il met à disposition sur lui-même.</p> <p>{siteName} n’est pas tenu de contrôler le caractère correct ou complet de ces indications.</p>
            <p>Lors de l'enregistrement du compte, le compte utilisateur est créé par {siteName} afin d'afficher les données sur les Services en ligne, ou pour tout besoin administratif. {siteName} est en droit de transmettre ou non les accès direct de gestion du compte utilisateur (un accès au backoffice de gestion). {siteName} est libre de gérer pour l'Utilisateur son compte sans donner un accès direct à l'interface de gestion.</p>
            <p>La connexion au Compte d’Utilisateur s’effectue éventuellement (si mis à disposition par {siteName}) au moyen des données d’accès créées lors ou suite à l’enregistrement (p. ex. nom d’utilisateur/mot de passe) ou par le biais d’une plateforme de tiers choisie par l’Utilisateur, resp. par le biais d’un fournisseur d’identité («identity provider»). {siteName} est en droit de considérer tout accès effectué au moyen de données d’accès correctes (nom d’utilisateur/mot de passe), ou tout accès authentifié par un fournisseur d’identité comme un accès dûment autorisé. {siteName} est libre de refuser une connexion pour des raisons légitimes, ou si ce mode de gestion des informations du compte n'est pas choisi par {siteName}.</p>
            <p>{siteName} est libre d’effacer un Compte d’Utilisateur ou de limiter l’accès à ce dernier en tout temps. Ce faisant, des contenus et informations partagés via le Compte d’Utilisateur peuvent continuer de rester affichés sur les Services de {siteName} ou les plateformes de tiers.</p>
            <p>{siteName} détermine le type de protection d’accès aux Services et prend des mesures afin de protéger ses Services contre des interventions non autorisées de tiers. Toutefois, une protection absolue contre des accès non autorisés de tiers n’existe pas. {siteName} n’est pas tenu, par exemple, de prendre des mesures de protection contre les copies.</p>
            <p>L’Utilisateur ne peut prévaloir d’aucun droit vis-à-vis de {siteName} découlant d’un éventuel abus relatif aux données d’accès ou des éventuels dommages qui lui seraient occasionnés en raison d’un accès abusif à son Compte d’Utilisateur ou résultant de l’utilisation des Services. Si l’Utilisateur constate des abus ou la présence d’offres ou de contenus illicites, ou en présume l’existence, il est tenu d’en informer immédiatement {siteName} (contact@localgraph.ch).</p>
            <p>Il est interdit à l’Utilisateur de transférer ou de céder à des tiers le Compte d’Utilisateur ou les droits y afférents.</p>
            <h3>2.3 Publication des contenus</h3>
            <p>L’Utilisateur peut transmettre et demander à modifier sa propre inscription ainsi que, le cas échéant, des contenus complémentaires tels que p. ex. photos, textes, vidéos, renvois à des sites web et autres contenus multimédia (regroupés sous le terme de «Contenus»), tels que prévu dans le forfait dûment payé, sous réserve de validation éventuelle par {siteName}. {siteName} se réserve le droit de contrôler et de corriger ces Contenus et, le cas échéant, de refuser des demandes de modifications de l’Utilisateur.</p>
            <p>L’Utilisateur déclare approuver la mise en ligne et la publication de ces Contenus sur les Services correspondants, dans les médias imprimés ainsi que sur les plateformes de partenaires et les plateformes de tiers, et confère à {siteName} les droits y afférent requis. Dans ce contexte, la question de savoir où, comment et si ces Contenus seront publiés ou non relève de la libre appréciation de {siteName}.</p>
            <p>L’Utilisateur peut demander à adapter des Contenus erronés ou non actuels. Ce faisant, l’Utilisateur assume la responsabilité de la mise à disposition des Contenus en dû temps et de manière techniquement irréprochable. L’Utilisateur assume la responsabilité liée au risque de transmission. Si des Contenus ne peuvent pas être publiés ou s’ils sont publiés de manière fausse parce que le Contenu n’a pas été mis à disposition par l’Utilisateur en dû temps, ou parce que ce Contenu a été mis à disposition de manière défectueuse ou erronée, l’Utilisateur ne peut en déduire aucun droit vis-à-vis de {siteName}.</p>
            <p>{siteName} peut refuser, modifier ou effacer des Contenus de l’Utilisateur selon sa propre appréciation et sans en indiquer les motifs, en raison de leur contenu concret, de leur provenance, de leur forme technique ou de leur qualité, en particulier lorsque les Contenus ne sont pas conformes aux garanties mentionnées sous chiffre 4. L’Utilisateur ne peut se prévaloir d’aucun droit vis-à-vis de {siteName} résultant du refus, de la modification ou de l’effacement d’un Contenu.</p>
            <p>Les Contenus qui sont publiés dans les Services reflètent le point de vue subjectif de l’Utilisateur, et ne reflètent pas l’opinion de {siteName}. Leur publication n’implique pas non plus l’approbation ou le consentement de {siteName} concernant les Contenus affichés. {siteName} n’est pas tenu de contrôler les Contenus et décline toute responsabilité quant à leur caractère correct.</p>
            <h2>3. Services de tiers</h2>
            <p>{siteName} peut intégrer des Services de tiers dans ses propres Services. Par exemple, certaines fonctionnalités des Services peuvent prévoir des interactions entre l’Utilisateur et des tiers, resp. entre l’Utilisateur et des Services de ces tiers. Ainsi, par exemple, les Services de tiers peuvent permettre à l’Utilisateur de partager des Contenus qui seront ensuite publiés sur les Services de tiers.</p>
            <p>La manière dont l’Utilisateur entend interagir avec des Services de tiers de ce genre relève de la responsabilité exclusive de l’Utilisateur. {siteName} n’a aucune influence sur l’aménagement de ces Services de tiers et sur leurs contenus. L’Utilisateur ne peut se prévaloir d’aucun droit vis-à-vis de {siteName} résultant de l’utilisation de ces Services de tiers.</p>
            <p>Par exemple, l'utilisation de Google Maps est soumise aux «Google Maps/Google Earth Additional Terms of Service»  (https://maps.google.com/help/terms_maps.html) et au «Google Privacy Policy» (https://www.google.com/policies/privacy).</p>
            <h2>4. Obligations de l’Utilisateur</h2>
            <p>L’Utilisateur s’engage à utiliser les Services de manière conforme à leur destination et à s’abstenir de toute utilisation non conforme à leur but, abusive ou illicite, soit p. ex. de recourir à des logiciels malveillants (tels que virus, vers («worms») ou autres logiciels d’espionnage).</p>
            <p>En outre, il est interdit à l’Utilisateur d’utiliser les Services de manière à affecter leur disponibilité pour {siteName} ou pour d’autres Utilisateurs, ou d’affecter les Services de quelque autre manière (p. ex. en faisant appel à des appareils automatiques, à des scripts, ou à des services de robots, de spiders, de crawlers ou de scrapers).</p>
            <p>L’Utilisateur garantit que les Contenus saisis ou publiés par ses soins ou mis à disposition d’une autre façon en lien avec les Services ne contreviennent pas aux dispositions légales, aux droits de tiers ou aux bonnes mœurs.</p>
            <p>En particulier, l’Utilisateur s’engage à ne pas publier des Contenus qui enfreignent les droits de la personnalité de tiers, qui contreviennent au droit de la concurrence ou à des obligations de confidentialité, ou qui portent atteinte à l’honneur, resp. des Contenus racistes, incitant à la violence, extrémistes, menaçants, dénigrants ou offensants, pornographiques ou obscènes, ou qui enfreignent des droits de tiers (comme par exemple les droits d’auteur, les droits des marques et les droits liés à la raison sociale).</p>
            <p>{siteName} est en droit de bloquer immédiatement et sans préavis, d’effacer, resp. de ne pas publier des Contenus qui contreviennent à ces règles.</p>
            <h2>5. Propriété intellectuelle</h2>
            <p>L’Utilisateur reconnaît qu’en règle générale, les Services, les Services de tiers, et les Contenus publiés par {siteName}, des tiers ou d’autres utilisateurs sur lesdits Services sont juridiquement protégés, et qu’une utilisation de ces Services et Contenus nécessite le consentement préalable du détenteur respectif des droits en question.</p>
            <p>Sont interdites la reproduction, la transmission (par voie électronique ou par d’autres moyens) ainsi que la modification de tout ou partie des Services ou Contenus. Copier, télécharger ou imprimer les Services ou Contenus n’est autorisé que pour un usage personnel et non commercial, et uniquement dans la mesure où l’usager n’efface aucune mention relative à la propriété intellectuelle, ni d’autres désignations protégées par la loi.</p>
            <p>Est interdite l’utilisation, en tout ou partie ou sous forme d’extraits, des répertoires d‘adresses mis à disposition via les Services aux fins d’exploitation commerciale de ces adresses, de fourniture de renseignements commerciaux ou sous forme de document ou de moyens auxiliaires visant à compiler ou à compléter des répertoires de participants, des répertoires d’adresses ou autres types de répertoires sous quelque forme que ce soit (sous forme électronique ou imprimée, sur des supports de données, etc.) ainsi que l’extraction des données sur Internet aux fins précitées, ainsi que pour tout autre type d’utilisation commerciale.</p>
            <h2>6. Responsabilité</h2>
            <p>Toute responsabilité civile de {siteName} vis-à-vis des Utilisateurs en lien avec l’utilisation des Services est exclue dans la mesure autorisée par la loi. En particulier, l’Utilisateur prend acte du fait que {siteName} ne répond pas des Contenus publiés par d’autres utilisateurs sur les Services, et qu’il ne répond pas non plus des contenus d’autres sites web et applications mobiles auxquels renvoient les Services. {siteName} décline aussi toute responsabilité quant aux Services de tiers.</p>
            <p>L’Utilisateur s’engage à libérer intégralement {siteName} de toute prétention en responsabilité formulée par des tiers imputable au non-respect des présentes Conditions d’utilisation.</p>
            <h2>7. Non-respect des Conditions d'Utilisation</h2>
            <p>En cas de non-respect des présentes Conditions d’utilisation, {siteName} est en droit de bloquer, resp. de refuser à un Utilisateur l’accès aux Services indiqués.</p>
            <h2>8. Dispositions finales</h2>
            <p>{siteName} se réserve le droit de modifier les présentes Conditions d’utilisation en tant que de besoin. La version actuelle respective consultable sous ce site fait foi.</p>
            <p>Si une ou plusieurs dispositions sont nulles ou sans effet, la validité des autres dispositions n'en est pas affectée.</p>
            <p>Le droit suisse est applicable aux présentes Conditions d’utilisation, à l’exclusion des règles du droit international privé.</p>
            <p>Le for exclusif pour tout litige résultant des présentes Conditions d’utilisation ou lié à ces dernières est le siège de Lyketil Sàrl (Lausanne).</p>
            <p>Lyketil Sàrl, {today.getMonth()}/{today.getFullYear()}</p>
        </Layout>
    )
}

export default ConditionsPage

const pageQuery = graphql`
  query conditionsQuery {
    site {
      siteMetadata {
        siteTitle
        siteDesc
        siteTagline
      }
    }
  }
`